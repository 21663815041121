import "./sass/index.scss"
import Weather2 from "./Weather2"

function App() {
  return (
    <div className="App">
      <Weather2 />
    </div>
  );
}

export default App;
